import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"

const AboutSection2 = ({ _logoSection, _section2 }) => {
  let frontmatter = {}
  let section2 = []

  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(AboutS2)/" } }) {
          nodes {
            exports {
              data {
                ImgSrc
                Name
                Role
                socialMedia
                description
              }
            }
            frontmatter {
              title
              ImgSrc
            }
          }
        }
      }
    `)
    section2 = result.allMdx.nodes[0].exports.data

    frontmatter = result.allMdx.nodes[0].frontmatter
  } catch (error) {
    frontmatter = _logoSection
    section2 = _section2
  }
  return (
    <div
      id="AboutSection2"
      className="AboutSection2 text-start"
      data-testid="AboutS2"
    >
      <h2>{frontmatter.title}</h2>
      <div className="row">
        {section2.map((item, index) => {
          return (
            <div className="col-12 col-md-4 pb-5" key={index}>
              <div className="row">
                <div className="col-2">
                  <img
                    src={item.ImgSrc}
                    alt="Maxapps: about us"
                    title="Maxapps: about us"
                  />
                </div>
                <div className="col-10">
                  <p className="Name">{item.Name}</p>
                  <h3 className="Role">{item.Role}</h3>
                  {/* <p className="socialMedia">{item.socialMedia}</p> */}
                  <p className="description">{item.description}</p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="text-center contain ">
        <img
          className="imageBottom"
          src={frontmatter.ImgSrc}
          alt="Maximo Ibm: contact us"
          title="Maximo Ibm: contact us"
        />
      </div>
    </div>
  )
}

export default AboutSection2
