import React from "react"
import About from "../components/about"
import Layout from "../components/layout/t1/layout"

const HomePage = () => {
  return (
    <Layout>
      <About />
    </Layout>
  )
}

export default HomePage
