import React from "react"
import AboutSection1 from "./section-about1"
import AboutSection2 from "./section-about2"
import AboutSection3 from "./section-about3"
import Seo from "../seo"

const About = () => {
  return (
    <div id="About" className="About py-5">
      <Seo
        title="Make building IBM Maximo apps fast and awesome"
        description="Thousands of users have used MAXapps for their daily operations. From planning, executing jobs and tracking resources to inventory management..."
        titleog="MAXapps About Us"
        descriptionog="We’re on a mission to make building IBM Maximo apps fast and awesome."
        image="https://maxapps-maximo.com/og/about-us.jpg"
        url="https://maxapps-maximo.com/about-us/"
      />
      <AboutSection1 />
      <AboutSection2 />
      <AboutSection3 />
    </div>
  )
}

export default About
