import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"

const AboutSection1 = ({ _logoSection }) => {
  let frontmatter = {}
  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(AboutS1)/" } }) {
          nodes {
            frontmatter {
              title1
              title2
              description
            }
          }
        }
      }
    `)
    frontmatter = result.allMdx.nodes[0].frontmatter
  } catch (error) {
    frontmatter = _logoSection
  }
  return (
    <>
      <div
        id="Aboutsection1"
        className="Aboutsection1  text-start"
        data-testid="AboutS1"
      >
        <div className=" container">
          <h1 dangerouslySetInnerHTML={{ __html: frontmatter?.title1 }}></h1>
          <p
            className="description1"
            dangerouslySetInnerHTML={{ __html: frontmatter?.title2 }}
          ></p>
        </div>
      </div>

      <div
        id="Aboutsection2"
        className="Aboutsection2  text-start"
        data-testid="AboutS2"
      >
        <div>
          <p>
            <span
              className="title2"
              dangerouslySetInnerHTML={{ __html: frontmatter?.description }}
            ></span>
          </p>
        </div>
      </div>
    </>
  )
}

export default AboutSection1
