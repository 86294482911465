import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"
import YellowButton from "../../home/buttons/yellow-button"
function AboutSection3({ _logoSection, btn = true }) {
  let frontmatter = {}
  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(AboutS3)/" } }) {
          nodes {
            frontmatter {
              title
              description
              buttonTitle
            }
          }
        }
      }
    `)
    frontmatter = result.allMdx.nodes[0].frontmatter
  } catch (error) {
    frontmatter = _logoSection
  }
  return (
    <div
      id="Aboutsection3"
      className="AboutSection3 text-center"
      data-testid="AboutS3"
    >
      <h2 className="title">{frontmatter.title}</h2>
      <div className="d-flex justify-content-center ">
        <p
          className="col-12 col-md-8"
          dangerouslySetInnerHTML={{ __html: frontmatter?.description }}
        ></p>
      </div>
      {btn && (
        <YellowButton
          data={frontmatter.buttonTitle}
          size={"180px"}
          to="/contact/"
        />
      )}
    </div>
  )
}

export default AboutSection3
